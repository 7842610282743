@import './addMoreden.scss';

.integration-editor {	
  &__bid {
    border-bottom: 2px solid #F07878;
  }
  &__a {
    color: #F07878 !important;
    &::visited {	
      color: #FFAEAE !important;
    }	
  }
}

.integration-editor__menubar-wrap {
  top: 0px !important;
}