@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Pretendard", sans-serif;
  }
}

@font-face {
  font-family: "Pretendard";
  src: url("../public/fonts/Pretendard-T.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("../public/fonts/Pretendard-EL.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("../public/fonts/Pretendard-L.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("../public/fonts/Pretendard-R.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("../public/fonts/Pretendard-M.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("../public/fonts/Pretendard-SB.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("../public/fonts/Pretendard-B.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("../public/fonts/Pretendard-EB.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("../public/fonts/Pretendard-BL.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

.inner.page {
  margin-bottom: 100px;
}

.page-tit {
  font-weight: 700;
  font-size: 35px;
  color: #333;
}

.page-detail-tit {
  font-weight: 700;
  font-size: 35px;
  color: #999;
}

.page-sub-tit {
  font-weight: 700;
  font-size: 30px;
  color: #666;
}

span.page-tit + span.page-sub-tit {
  margin-left: 10px;
}

.text-input {
  border-radius: 5px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.24);
  background-color: #fff;
  color: #333;
  padding: 8px 10px;
  font-size: 16px;
  width: 380px;
}

.text-input.full {
  border-radius: 5px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.24);
  background-color: #fff;
  color: #333;
  padding: 8px 10px;
  width: 100%;
}

.text-input::placeholder {
  color: #ccc;
  font-size: 16px;
}

.text-input:focus {
  outline: none;
}

button {
  border-radius: 5px;
  color: #f07878;
  border: 1px solid #f07878;
  padding: 6px 18px;
  min-width: 60px;
  max-height: 40px;
  background-color: #fff;
}

button.main {
  background-color: #f07878;
  border: 1px solid #f07878;
  color: #fff;
}

button.main-white {
  background-color: #fff;
  border: 1px solid #f07878;
  color: #f07878;
}

button.dark {
  background-color: #333;
  border: 1px solid #333;
  color: #fff;
}

button.white {
  color: #333;
  border: 1px solid #333;
  background-color: #fff;
}

button + button {
  margin-left: 10px;
}

select {
  border-radius: 5px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.24);
  background-color: #fff;
  padding: 8px 10px;
  font-size: 16px;
  text-align: center;
}

select:invalid {
  color: #aaa;
}

.header-shadow {
  box-shadow: 0px 4px 4px 3px rgba(134, 134, 134, 0.25);
}

summary {
  cursor: pointer;
}

.checkbox {
  display: none;
}

.checkbox + label {
  display: inline-block;
  width: 17px;
  height: 17px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #e1e1e1;
  margin-right: 10px;
  position: relative;
}

.checkbox:checked + label::after {
  content: "✓";
  display: inline-block;
  color: #6ea7db;
  text-align: center;
  position: absolute;
  left: 1px;
  top: -4px;
}

.table {
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  color: #666;
  margin-top: 20px;
}

.table .row {
  width: 1200px;
  padding: 5px;
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid #999;
  align-items: center;
}

.table .row div {
  display: flex;
  align-items: center;
  line-break: anywhere;
}

.table .row div div.content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table .row.header {
  background: #6ea7db;
  color: #fff;
  border-bottom: none;
}

.table .row.detail {
  background: #f8fbff;
  border-bottom: none;
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.table .row.detail div {
  position: relative;
}

.table .row.detail div > span {
  font-size: 20px;
  position: absolute;
  left: -27px;
}

.table .row button {
  padding: 5px 4px;
}

.ellipsis {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.memberInfo-cell {
  min-height: 50px;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.paging ul {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto;
  width: 350px;
  margin-top: 40px;
}

.paging ul .previous {
  font-size: 20px;
}

.paging ul .next {
  font-size: 20px;
}

.paging ul .previous.disabled {
  display: none;
}

.paging ul .num.current {
  font-size: 20px;
  color: #6ea7db;
}

.paging ul .num {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #333;
}

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 509;
}

.checkbox {
  background-color: #d9d9d9;
  border-radius: 0.375rem;
  border-style: none;
  appearance: none;
  cursor: pointer;
}

.checkbox:checked {
  background: no-repeat url("/public/check.svg") center/cover;
}

#froala_editor button {
  border-radius: inherit;
  color: inherit;
  border: none;
  padding: inherit;
  min-width: inherit;
  max-height: inherit;
  background-color: inherit;
}

.pagination .page {
  width: 38px;
  height: 38px;
  background-color: #f5f5f5;
  color: #aeaeae;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination .page.active {
  color: #fff;
  background-color: #f07878;
}

/* 이미지 확대 */
#img-zoom {
  overflow: hidden;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 1000px;
  max-height: 80vh;
  line-height: 0;
}

#img-zoom .zoom-img__close {
  position: absolute;
  background-color: #333;
  border-radius: 5px;
  font-size: 20px;
  right: 30px;
  top: 30px;
  z-index: 999999;
  height: 25px;
  width: 25px;
  opacity: 0.8;
  cursor: pointer;
}

#img-zoom .zoom-img__close::after {
  content: "✕";
  position: relative;
  left: 5px;
  top: 12px;
  color: #fff;
}

#img-zoom img {
  width: 100%;
}

.App-footer {
  position: absolute;
  bottom: 0px;
  text-align: center;
  width: 100%;
  background-color: transparent;
}

.App-indicator {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 15px;
  background-color: #111;
  color: #fff;
  border-radius: 4px 4px 0px 0px;
  opacity: 0.8;
}

.App-button {
  width: 32px;
  height: 32px;
  padding: 0;
  text-align: center;
  border: none;
  border-radius: 50%;
  outline: none;
  background: none;
  color: #fff;
  font-size: 0.75rem;
  cursor: pointer;
  vertical-align: middle;
}

.App-buttonIcon {
  display: block;
  fill: currentColor;
  height: 100%;
}

.App-zoomLabel {
  display: inline-block;
  width: 60px;
  vertical-align: middle;
  color: #fff;
  font-size: 18px;
}

.popup-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 509;
  background-color: rgba(0, 0, 0, 0.4);
}
