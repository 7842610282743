@import 'defaultStyle.scss';

a {
  text-decoration: none;
}

.article-editor-pm {	
  border: 1px solid #ccc;	
  border-radius: 6px;	
  padding: 0 8px;	
  @media (max-width: 799px){	
    border: none;	
    padding: 0;	
  }	
}

.integration-editor {	
  width: 97%;	
  margin: none;
  padding: 23px 16px 200px 16px;	
  @media (max-width: 799px) {	
    width: 91%;
  }

  &__p {
    line-height: 180%;	
    min-height: 25.5px;
  }
  &__bdi {
    border-bottom: 2px solid #4a25aa !important; 
  }
  &__heading {
    font-size: 18px;
  }
  &__hrWrapper {
    padding: none;
  }
  &__img {	
    margin: 10px 0;	
    display: initial !important;	
  }
  &__a {
    color: #4a25aa !important; 
    &::visited {	
      color: rgb(142, 68, 173) !important;	
    }	
  }
  &__ul{	
    list-style: disc !important;	
    padding-left: 30px;
  }
  &__ol {	
    list-style: decimal !important;	
  }
  &__li {	
    list-style: inherit !important;	
  }
  &__table {
    tbody th {
      text-align: inherit;
      background-color: #f1f3f8;
    }
  }
}

.integration-editor__menubar-wrap {
  top: 56px !important;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  height: 50px;
}

// 메뉴바 스타일 정의
.integration-editor__menubar {
  .group {
    position: relative;
    @media (max-width: 799px) {
      position: static !important;
    }
    &:hover {
      .tooltip-txt {
        display: inline-block;

        @media (max-width: 799px) {
          display: none;
        }
      }
    }
    
    .tooltip-txt {
      background-color: #333;
      color: #fff;
      padding: 1px 4px;
      border-radius: 2px;
      position: absolute;
      left: -12px;
      width: 70px;
      text-align: center;
      display: none;
      font-size: 10px;
    }
  }
  
  justify-content: start;
  box-shadow: none;
  border-top: none;
  &__icon.highlighted {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 40px;
    height: 40px;
    margin-right: 2px;
    border-radius: 3px;
    background-color: var(--bg-color);
    cursor: pointer;
    &:hover {
      filter: var(--button-hover);
    }
    path {
      fill: var(--fg-color);
    }
    @media (max-width: 799px) {	
      width: 35px;	
      height: 35px;	
      margin-right: 4px;	
    }
  }
  &__icon.dropdown-text-item {
    margin: 0;
  }
  &__color-palette {
    padding: 20px;	
    &__color {	
      border: none;
      &:hover {	
        outline:1px solid #333;	
        z-index: 2;	
        box-shadow: none;
      }
    }
  }
  &__fontSize-box,
  &__textFormat-box {
    margin: auto;
  }
  @media (max-width: 799px) {
    &__icon.file {
      display: none;
    }
  }
}
@media (max-width: 799px) {
  .integration-editor__menubar {
    overflow-x: scroll;
    position: static;
  }
  .integration-editor__menubar::-webkit-scrollbar {
    display: none;
  }
}