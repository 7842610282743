// User Agent 기본 스타일 덮어쓰기
a {
  text-decoration: underline;
}

sup {
  top: initial;
  font-size: initial;
  line-height: initial;
  vertical-align: initial;
  position: relative;
}

// 에디터 스타일 변수 정의
:root {
  --fg-color: #000000;
  --bg-color: #ffffff;
  --popup-bg-color: #ffffff;
  --popup-shadow: 1px 1px 4px 1px #58585833;
  --button-hover: brightness(95%);
  --code-color: #7a869a;
  --code-bg-color: #eff0f2;
  --image-controller: #ffffff;
  --image-controller-border: #75bbeb;
  --border-color-hovered: #eeeeee;

  .dark-theme {
    --fg-color: #ffffff;
    --bg-color: #000000;
    --popup-bg-color: #ffffff;
    --popup-shadow: none;
    --button-hover: brightness(50%);
    --code-color: #e7e7e7;
    --code-bg-color: #797a7c;
    --image-controller: #000000;
    --image-controller-border: #75bbeb;
    --border-color-hovered: #474747;
  }
}

// 선택 가능한 노드에 마우스 호버할 때의 스타일
@mixin hover-selectable-node {
  &:not(.ProseMirror-selectednode):hover {
    outline: 4px solid var(--border-color-hovered);
  }
}

// ProseMirror 기본 스타일 덮어쓰기
.ProseMirror {
  // footnote counter
  counter-reset: prosemirror-footnote;

  // 선택된 노드에 테두리를 추가합니다.
  &-selectednode {
    outline: 4px solid #75bbeb;
  }

  // footnote 입력창에 placeholder 를 추가합니다. (placeholder 플러그인 참고)
  &[data-placeholder]::before {
    color: rgb(210, 210, 210);
    position: absolute;
    content: attr(data-placeholder);
    pointer-events: none;
  }

  // 선택된 테이블 셀의 배경색을 설정합니다.
  .selectedCell::after {
    background: rgba(51, 159, 255, 0.231372549);
    border: 1px solid #0065ff;
  }
}

// 에디터 요소 스타일 정의
.integration-editor {
  min-height: 500px;
  width: 700px;
  box-sizing: content-box;
  margin: auto;
  padding: 23px 0 200px 0;
  color: var(--fg-color);

  @media (max-width: 799px) {
    width: 96%;
  }

  &:focus {
    outline: none;
  }

  &__p {
    font-size: 15px;
    letter-spacing: -0.3px;
    line-height: 190%;
    min-height: 28.5px;
  }

  &__heading {
    margin: 10px 0;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.6px;
    line-height: 180%;
    min-height: 39.6px;
  }

  &__hrWrapper {
    padding: 50px 0;
    cursor: pointer;
    @include hover-selectable-node; // TODO: selectable-guide 로 옮기기
  }

  &__hr {
    clear: both;
    margin: 50px 0;
    border: solid #dedede;
    border-width: 0 0 1px;
  }

  &__img {
    max-width: 100%;
  }

  &__a {
    color: #1bbc9c !important;
    font-weight: 700;
    text-decoration: underline;
    text-underline-position: under;
    word-break: break-all;
    word-wrap: break-word;

    &[download='']::after {
      content: "📎";
    }
  }

  &__ul,
  &__ol {
    padding-left: 30px;
  }

  &__blockquote {
    margin: 20px 0;
    padding-left: 6px;
    border-left: 2px solid #666;
    line-height: 170%;
    letter-spacing: -0.3px;
    color: #7c7c7c;

    img {
      max-width: 100%;
    }

    p {
      font-size: 13px;
      line-height: inherit;
    }
  }

  &__pre {
    white-space: pre-wrap;
    background-color: #f7f6f3;
    padding: 10px;
  }

  &__code {
    background-color: none;
  }

  &__video {
    max-width: 100%;
  }

  &__iframeWrapper {
    display: flex;
    position: relative;
    cursor: pointer;
    @include hover-selectable-node; // TODO: selectable-guide 로 옮기기
  }

  &__iframe-resizer {
    position: absolute;
    display: none;
    bottom: -8px;
    right: -8px;
    width: 10px;
    height: 10px;
    background-color: var(--bg-color);
    border-color: black;
    border: 3px solid #75bbeb;
    cursor: ns-resize;
  }

  &__iframe {
    width: 95%;
    margin: auto;

    &[data-platform='youtube'] {
      aspect-ratio: 16 / 9;
      width: 100%;
    }

    &[data-platform='kollus'] {
      aspect-ratio: 16 / 9;
      width: 100%;
      margin: inherit;
    }
  }

  &__tableWrapper {
    padding: 6px;
    overflow-x: auto;
  }

  &__table {
    border-collapse: collapse;
    table-layout: auto;
    width: 100%;
    overflow: hidden;
    margin: 10px 0;

    &[data-layout='fixed'] {
      table-layout: fixed;
    }

    col {
      min-width: 50px;
    }

    row {
      min-height: 20px;
    }

    tbody th {
      font-weight: inherit;
      text-align: initial;
      vertical-align: inherit;
    }
  }

  &__table.transparent {

    td,
    th {
      border-color: transparent;
    }
  }

  &__table-cell {
    min-width: 1em;
    border: 1px solid #ddd;
    padding: 5px 10px;
    vertical-align: top;
    box-sizing: border-box;
    position: relative;

    img {
      max-width: 100%;
    }
  }

  // 모바일 화면에서의 auto layout table 은 셀의 넓이를 지정하지 않습니다.
  @media (max-width: 799px) {
    &__table[data-layout='auto'] {

      td,
      th {
        width: auto !important;
      }
    }
  }

  &__footnote {
    display: inline-block;
    position: relative;
    cursor: pointer;

    &::after {
      content: counter(prosemirror-footnote);
      vertical-align: super;
      font-size: 75%;
      counter-increment: prosemirror-footnote;
    }

    .footnote-tooltip *::selection {
      background-color: transparent;
    }

    .footnote-tooltip *::-moz-selection {
      background-color: transparent;
    }

    .footnote-tooltip {
      cursor: auto;
      position: absolute;
      left: -30px;
      top: calc(100% + 10px);
      background: rgb(255, 255, 255);
      border: 3px solid black;
      padding: 3px;
      border-radius: 2px;
      width: 500px;
      z-index: 2;
    }

    .footnote-tooltip::before {
      border: 5px solid black;
      border-top-width: 0px;
      border-left-color: transparent;
      border-right-color: transparent;
      position: absolute;
      top: -5px;
      left: 27px;
      content: ' ';
      height: 0;
      width: 0;
    }
  }

  &__image-resize-handle-wrapper {
    position: absolute;
    bottom: -7px;
    right: -7px;
    width: 20px;
    height: 20px;
    cursor: nwse-resize;
    display: none;
  }

  &__image-resize-handle {
    position: absolute;
    bottom: -7px;
    right: -7px;
    width: 6px;
    height: 6px;
    background-color: var(--image-controller);
    border: solid 2px var(--image-controller-border);
    display: none;
    box-sizing: content-box;
    z-index: 1;
    touch-action: none;
    cursor: nwse-resize;

    &:hover {
      background-color: var(--image-controller-border);
    }
  }

  &__image-menu-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: row-reverse;
  }

  &__image-menu-button {
    padding: 6px 8px;
    margin: 0 2px;
    color: #ffffff;
    background-color: #00000040;
    border: solid 1px #ffffff40;
    border-radius: 3px;
    fill: var(--image-controller);
    display: none;
    z-index: 1;
    cursor: pointer;

    &:hover {
      background-color: var(--image-controller-border);
      border-color: var(--image-controller-border);
    }
  }

  &__image-align-wrapper {
    position: absolute;
    top: 36px;
    left: 2px;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    background-color: #00000040;
    border: solid 1px #ffffff40;
  }

  &__image-align-button {
    padding: 6px 8px;
    color: #ffffff;
    background-color: transparent;
    border-radius: 3px;
    border: solid 1px transparent;
    fill: var(--image-controller);
    display: none;
    z-index: 1;
    cursor: pointer;

    &:hover {
      background-color: var(--image-controller-border);
      border-color: var(--image-controller-border);
    }
  }

  &__custom {
    padding: 13px 20px 12px 12px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid var(--blue-grey-300-to-600);
    background: var(--blue-grey-50-to-900);
    margin: 10px 0;
    display: flex;
    gap: 12px;
    white-space: pre-wrap;
    text-align: center;
    cursor: pointer;
  }

  .selectable-guide {
    @include hover-selectable-node;
  }

  .icon-wrapper {
    width: 42px;
    height: 42px;
    border-radius: 4px;
    background: var(--blue-50-to-blue-grey-800);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .type-div {
    color: var(--blue-grey-700-to-200);
    font-family: Pretendard;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.3px;
    text-transform: capitalize;
    min-width: 100px;
    display: flex;

    @media (max-width: 799px) {
      min-width: 50px;
    }
  }

  .title-div {
    overflow: hidden;
    color: var(--blue-grey-700-to-200);
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Pretendard;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.4px;
    text-transform: capitalize;
  }
}

.input-dialog,
.link-input-dialog,
.iframe-link-input-dialog {
  z-index: 10;
  background: var(--popup-bg-color);
  padding: 10px 15px;
  box-shadow: var(--popup-shadow);
  border-radius: 3px;

  &__form {
    display: flex;
    justify-content: space-around;
  }

  &__input {
    width: 300px;

    @media (max-width: 799px) {
      width: 230px;
    }

    height: 30px;
    font-size: 14px;
    margin-right: 10px;
    padding-left: 5px;
    border: none;
    border-bottom: 1px solid #ebebeb;
    color: dimgrey;
  }

  &__button {
    border: none;
    outline: none;
    border-radius: 3px;
    width: 48px;
    height: 30px;
    background-color: whitesmoke;
    font-size: 12px;
    color: dimgrey;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      filter: brightness(95%);
    }
  }
}

.link-input-dialog {
  position: absolute;
  display: none;

  @media (max-width: 799px) {
    left: 3px !important;
  }
}

.iframe-link-input-dialog {
  position: fixed;
  display: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.html-insert-dialog-background {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000040;
  z-index: 9999;

  .html-insert-dialog {
    background: var(--popup-bg-color);
    padding: 10px 15px;
    box-shadow: var(--popup-shadow);
    border-radius: 3px;
    width: 80%;
    height: 80%;

    &__form {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      height: 100%;
    }

    &__input {
      width: 100%;
      height: 80%;
      font-size: 14px;
      border: none;
      border: 1px solid #ebebeb;
      padding: 4px;
      box-sizing: border-box;
      line-height: 1.5;
      color: rgb(25, 25, 25);
      resize: none;
      white-space: pre;
    }

    &__button {
      border: none;
      outline: none;
      border-radius: 3px;
      width: 100%;
      height: 40px;
      background-color: whitesmoke;
      font-size: 12px;
      color: dimgrey;
      font-weight: 500;
      cursor: pointer;

      &:hover {
        filter: brightness(95%);
      }
    }
  }
}

.integration-editor__cellmenu {
  &__buttonContainer {
    width: 16px;
    height: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    border: 1px solid #f5f6fa;
    border-radius: 3px;
    background-color: white;

    .menuButton {
      padding: 0;
      width: 16px;
      height: 13px;
      border: 1px solid #f5f6fa;
      border-radius: 3px;
      background-color: #f5f6fa;
      cursor: pointer;

      &:hover {
        background-color: #dcdde1;
      }

      &:focus {
        background-color: black;

        path {
          fill: white;
        }
      }

      &:active {
        background-color: rgba(162, 222, 245, 0.7);

        path {
          fill: rgb(2, 2, 97)
        }
      }
    }
  }

  &__tableMenu {
    padding: 0px !important;
    position: absolute;
    right: 0px;
    list-style-type: none;
    background-color: var(--bg-color);
    border-radius: 3px;
    box-shadow: var(--popup-shadow);
    z-index: 2;
    margin: 5px 2px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4px 0;
    border-radius: 0;
    width: auto;
    min-width: 160px;
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    color: var(--fg-color);
    background-color: var(--bg-color);
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background-color: #f2f2f2;
    }

    code {
      background-color: var(--code-bg-color);
      color: var(--code-color);
      border-radius: 3px;
      padding: 4px;
      margin-left: 8px;
      line-height: 12px;
      font-size: 11.5px;
      align-self: flex-end;
      font-weight: 400;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }
  }

  &__palette {
    display: none;
    position: absolute;
    left: -110px;
    top: 0px;
    z-index: 1;
    background-color: var(--bg-color);
    box-shadow: var(--popup-shadow);
    border-radius: 3px;
    box-sizing: border-box;
    overflow: auto;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 25px);
    grid-auto-flow: inherit;
    padding: 10px;

    &-cellBgColor {
      width: 27px;
      height: 27px;
      border: 1px solid rgba(238, 238, 238, 0.6);
      border-radius: 2px;
      box-sizing: border-box;
      margin: 0;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 0px 1px #e9e9e9;
      }
    }

    &-resetStyle::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top left,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) calc(50% - 0.8px),
          rgb(255, 0, 0) 50%,
          rgba(0, 0, 0, 0) calc(50% + 0.8px),
          rgba(0, 0, 0, 0) 100%);
    }
  }
}

.integration-editor__menubar-wrap {
  position: sticky;
  z-index: 10;
  top: 80px;

  @media (max-width: 799px) {
    top: 60px;
  }

  height: 60px;
}

// 메뉴바 스타일 정의
.integration-editor__menubar {
  position: absolute;
  width: 98%;
  z-index: 10;
  display: flex;
  justify-content: center;
  background-color: var(--bg-color);
  padding: 4px;
  margin: auto;
  max-width: 770px;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
  border-top: 5px solid #222;

  &__icon.strong,
  &__icon.em,
  &__icon.del,
  &__icon.textColor,
  &__icon.textHighlight,
  &__icon.underline,
  &__icon.textFormat,
  &__icon.fontSize,
  &__icon.blockquote,
  &__icon.alignLeft,
  &__icon.alignCenter,
  &__icon.alignRight,
  &__icon.link,
  &__icon.hr,
  &__icon.image,
  &__icon.video,
  &__icon.embed,
  &__icon.file,
  &__icon.emoji,
  &__icon.poll,
  &__icon.footnote {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 40px;
    height: 40px;
    margin-right: 2px;
    border-radius: 3px;
    background-color: var(--bg-color);
    cursor: pointer;

    &:hover {
      filter: var(--button-hover);
    }

    path {
      fill: var(--fg-color);
    }
  }

  &__icon.dropdown-preview {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 4px;
    height: 40px;
    margin-right: 2px;
    border-radius: 3px;
    background-color: var(--bg-color);
    cursor: pointer;

    &:hover {
      filter: var(--button-hover);
    }

    &::after {
      content: '▼';
      font-size: 8px;
      margin-left: 10px;
      color: var(--fg-color);
    }

    path {
      fill: var(--fg-color);
    }
  }

  &__icon.dropdown-preview.custom {
    display: none;
  }

  &__icon.active {
    &::after {
      content: '▼';
      font-size: 8px;
      margin-left: 10px;
      color: var(--blue-500);
    }

    path {
      fill: var(--blue-500);
    }
  }

  &__icon.dropdown-item {
    margin: 0;
    border-radius: 3px;
    width: auto;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 500;
    background-color: var(--bg-color);
  }

  &__icon.dropdown-text-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4px 0;
    border-radius: 0;
    width: auto;
    min-width: 160px;
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    color: var(--fg-color);
    background-color: var(--bg-color);
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      filter: var(--button-hover);
    }

    code {
      background-color: var(--code-bg-color);
      color: var(--code-color);
      border-radius: 3px;
      padding: 4px;
      margin-left: 8px;
      line-height: 12px;
      font-size: 11.5px;
      align-self: flex-end;
      font-weight: 400;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }
  }

  &__dropdown {
    display: none;
    padding: 0px !important;
    position: absolute;
    list-style-type: none;
    background-color: var(--bg-color);
    border-radius: 3px;
    box-shadow: var(--popup-shadow);
    z-index: 1;
  }

  &__color-palette {
    position: absolute;
    display: none;
    z-index: 1;
    background-color: var(--bg-color);
    box-shadow: var(--popup-shadow);
    border-radius: 3px;
    box-sizing: border-box;
    overflow: auto;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 25px);
    grid-auto-flow: inherit;
    padding: 10px;

    &__color {
      width: 27px;
      height: 27px;
      border: 1px solid rgba(238, 238, 238, 0.6);
      border-radius: 2px;
      box-sizing: border-box;
      margin: 0;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 0px 1px #e9e9e9;
      }
    }

    [data-color='reset-style']::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top left,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) calc(50% - 0.8px),
          rgb(255, 0, 0) 50%,
          rgba(0, 0, 0, 0) calc(50% + 0.8px),
          rgba(0, 0, 0, 0) 100%);
    }
  }

  &__fontSize-box,
  &__textFormat-box {
    position: absolute;
    display: none;
    margin: 16px 0;
    padding: 5px 0;
    z-index: 1;
    background-color: var(--bg-color);
    box-shadow: var(--popup-shadow);
    border-radius: 3px;
    box-sizing: border-box;
    overflow: auto;
    grid-auto-flow: inherit;
    text-align: left;

    &__fontSize,
    &__textFormat {
      width: 85px;
      height: 38px;
      line-height: 38px;
      margin: 0;
      padding: 0 10px;
      cursor: pointer;

      &:hover {
        background-color: #f2f2f2;
      }
    }
  }

  @media (max-width: 799px) {

    &__icon.em,
    &__icon.del,
    &__icon.underline,
    &__icon.link,
    &__icon.hr,
    &__icon.video,
    &__icon.embed,
    &__icon.dropdown-preview.textAlign,
    &__icon.dropdown-preview.table {
      display: none;
    }
  }
}

// 가상 커서의 스타일 정의
.ProseMirror-gapcursor {
  display: none;
  pointer-events: none;
  position: absolute;
}

.ProseMirror-gapcursor:after {
  content: '';
  display: block;
  position: absolute;
  top: 15px;
  width: 20px;
  border-top: 1px solid var(--fg-color);
  animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
}

@keyframes ProseMirror-cursor-blink {
  to {
    visibility: hidden;
  }
}

.ProseMirror-focused .ProseMirror-gapcursor {
  display: block;
}